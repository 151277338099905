import React from 'react';
import './OwlBanner.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { motion } from 'framer-motion';
import promotion1 from "../../assets/promotion/pm9.jpg";
import promotion2 from "../../assets/promotion/pm10.jpg";
import promotion3 from "../../assets/promotion/pm11.jpg";
import promotion4 from "../../assets/promotion/pm12.jpg";
import promotion5 from "../../assets/promotion/pm13.jpg";
import promotion6 from "../../assets/promotion/pm14.jpg";
import promotion7 from "../../assets/promotion/pm15.jpg";
import promotion8 from "../../assets/promotion/pm16.jpg";

export const OwlBanner: React.FC = () => {

  const options = {
    center: true,
    items: 3,
    loop: true,
    margin: 25,
    smartSpeed: 1000,
    autoplay: true,
    responsive: {
      1360: {
        items: 2.2,
      },
      1024: {
        items: 2.2,
      },
      768: {
        items: 1.2,
        stagePadding: 15,
      },
      712: {
        items: 1.2,
        stagePadding: 15,
      },
      375: {
        items: 1,
        stagePadding: 15,
      },
      360: {
        items: 1,
        stagePadding: 15,
      },


    },
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 1.3 } }}
      className="owl-container">
      <OwlCarousel className='owl-theme'
        {...options}
      >
        <div className='item'>
          <img src={promotion1} alt="pm1" />
        </div>
        <div className='item'>
          <img src={promotion2} alt="pm2" />
        </div>
        <div className='item'>
          <img src={promotion3} alt="pm3" />
        </div>
        <div className='item'>
          <img src={promotion4} alt="pm4" />
        </div>
        <div className='item'>
          <img src={promotion5} alt="pm5" />
        </div>
        <div className='item'>
          <img src={promotion6} alt="pm6" />
        </div>
        <div className='item'>
          <img src={promotion7} alt="pm7" />
        </div>
        <div className='item'>
          <img src={promotion8} alt="pm8" />
        </div>
      </OwlCarousel>

    </motion.div>



  );
};
