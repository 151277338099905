import React from 'react';
import './LoginModal.scss';
import { Button, Form, Input, Modal, Spin } from 'antd';
import { UserOutlined, KeyOutlined } from '@ant-design/icons';
import { login, setUserNameStorage } from 'api/auth';
import IntlMessages from 'components/Common/IntlMessages';


interface LoginFormProps {
  onSubmitResult(data: boolean): void;
  loginModalOpen: boolean
  onClose: () => void
}


export const LoginModal: React.FC<LoginFormProps> = ({ onSubmitResult, loginModalOpen, onClose }) => {
  const [loading, setLoading] = React.useState(false);

  // const onFinish = (values: any) => {
  //   onSubmit(values);
  // };

  const handleCancel = () => {
    // setIsModalOpen(false);
    onClose()
  };


  const onFinish = async (data: { username: string; password: string; remember: boolean }) => {
    try {
      setLoading(true)
      const loginResponse = await login(data.username, data.password);
      if (loginResponse?.result?.bearerToken) {
        setUserNameStorage(data.username);
        onSubmitResult(true)
      } else {
        onSubmitResult(false)
      }
    } catch (err) {
      onSubmitResult(false)
    } finally {
      setLoading(false)
    }
  };

  return (
    <Modal
      open={loginModalOpen}
      centered
      onCancel={handleCancel}
      footer={null}
      className="login-modal-root">
      <Spin spinning={loading}>
        <Form
          name="normal_login"
          className="login-form"
          layout="vertical"
          initialValues={{
            remember: true,
          }}

          onFinish={onFinish}
        >
          {/* <div className="header-login"> */}
            {/* <img src={logoCheckslip} className="brand-logo-checkslip" /> */}
            {/* <h2 className="title-highlight"> <IntlMessages id="menu.login" /></h2> */}
          {/* </div> */}
          <Form.Item
            name="username"
            label={ <IntlMessages id="username" />}
            rules={[
              {
                required: true,
                message: "กรุณากรอกบัญชีผู้ใช้ของท่าน",
              },
            ]}
          >
            <Input size="large" prefix={<UserOutlined className="site-form-item-icon-custom" />} placeholder="บัญชีผู้ใช้" />
          </Form.Item>
          <Form.Item
            name="password"
            label={ <IntlMessages id="password" />}
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              size="large"
              prefix={<KeyOutlined className="site-form-item-icon-custom" />}
              type="password"
              placeholder="รหัสผ่าน"
            />
          </Form.Item>
          <Form.Item>
            <Button size="large" loading={loading} type="primary" htmlType="submit" className="w-100 button-main">
            <IntlMessages id="menu.login" />
            </Button>
            {/* <Flex justify="space-between" style={{marginTop: "10px"}}>
          <Link className="text-login-form" to="#">
            ลืมรหัสผ่าน
          </Link>
        </Flex> */}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>



  );
};
