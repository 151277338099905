import React from 'react';
import './Wallet.scss';
import { formatNumberCurrency } from 'utils/number';
import {
  SyncOutlined,
} from '@ant-design/icons';
import walletIcon from "assets/wallet-icon.png";

interface WalletProps {
  wallet: any,
}
export const Wallet: React.FC<WalletProps> = ({
  wallet,
}) => {

  return (
    <div
      className="wallet-root">
        <img src={walletIcon} />
          <div className='text-number'>{formatNumberCurrency(wallet)}</div>
        <SyncOutlined spin />
    </div>



  );
};
