import { api } from "./instances";
import moment from 'moment';
export const TURNOVER_API = "/user/money/turnover"
export const TURNOVER_GAMETYPE_API = "/user/money/turnover/gametype"
export const MAXBET = "/user/max-bet"

export async function getTurnOver(startDateMilis: number, endDateMilis: number, gameType: number | null = null) {
  let body: any = { startDateMilis, endDateMilis: moment(endDateMilis).endOf('day').valueOf() }
  const response = await api.post(TURNOVER_API, body);
  return response.data
}

export async function getTurnOverByGameType(startDateMilis: number, endDateMilis: number, gameType: number | null = null) {
  let body: any = { startDateMilis, endDateMilis: moment(endDateMilis).endOf('day').valueOf() }
  const response = await api.post(TURNOVER_GAMETYPE_API, body);
  return response.data
}

export async function getMaxBetList() {
  const response = await api.get(MAXBET);
  return response.data
}
