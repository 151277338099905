import React, { CSSProperties, useEffect, useState } from 'react';
import { Grid, Row, Col, Space, Divider, Typography } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import { WalletTwoTone, CreditCardTwoTone } from '@ant-design/icons';
import './CardDataList.scss';
import IntlMessages from 'components/Common/IntlMessages';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { formatNumberCurrency } from 'utils/number';
const { Text } = Typography;
interface CardDataProps {
  dataList: any,
  totalWith: number,
  totalDep: number
};

export const CardDataList: React.FC<CardDataProps> = ({ dataList, totalDep = 0, totalWith = 0 }) => {
  const intl = useIntl()
  const { useBreakpoint } = Grid
  const breakpoints = useBreakpoint()
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState('statement')
  const pageParam = searchParams.get('page')
  const [totalDepPage, setTotalDepPage] = useState(0)
  const [totalWithPage, setTotalWithPage] = useState(0)
  const [settleAmount, setSettleAmount] = useState(0)
  const [settleAmountAllTime, setSettleAmountAllTime] = useState(0)

  useEffect(() => {
    if (pageParam) {
      setActiveTab(pageParam)
    }
  }, [pageParam])

  useEffect(() => {
    let totalDepPage = 0;
    let totalWithPage = 0;
    let settleAmount = 0;
    let settleAmountAllTime = 0;
    setTotalDepPage(0)
    setTotalWithPage(0)
    setSettleAmount(0)
    dataList.forEach(({ action, type }: { action: number, type: string }) => {
      if (type === 'Deposit-mem') {
        totalDepPage += action;
        setTotalDepPage(prev => prev + action)
      } else {
        totalWithPage += action;
        setTotalWithPage(prev => prev + action)
      }
    });
    setSettleAmount(totalWithPage - totalDepPage )
    settleAmountAllTime = totalWith - totalDep;
  }, [dataList])


  const rootStyle: CSSProperties = {
    padding: (() => {
      if (!breakpoints.lg) {
        return '10px 5px'
      } else {
        return '30px 50px'
      }
    })(),
  }

  return (
    <div className="card-data-list">
      <div style={{paddingBottom: '5px'}}>
        <Row>
          <Col span={14} style={{ color: '#838383', textAlign: 'right' }}>
            <IntlMessages id="nav.total" /> <IntlMessages id="history.deposit" />
          </Col>
          <Col span={10} style={{ textAlign: 'right' }}>
            {formatNumberCurrency(totalDepPage, 2, 2)}
          </Col>
        </Row>
        <Row>
          <Col span={14} style={{ color: '#838383', textAlign: 'right' }}>
            <IntlMessages id="nav.total" /> <IntlMessages id="history.withdraw" />
          </Col>
          <Col span={10} style={{ textAlign: 'right' }}>
            {formatNumberCurrency(totalWithPage, 2, 2)}
          </Col>
        </Row>
        {/* <Row>
          <Col span={14} style={{ color: '#838383', textAlign: 'right' }}>
            Balance
          </Col>
          <Col span={10} style={{ textAlign: 'right' }}>
            <Text strong type={settleAmount > 0 ? 'success' : 'danger'}>{settleAmount > 0 ? '+' : ''} {formatNumberCurrency(settleAmount, 2, 2)} </Text>
          </Col>
        </Row> */}
      </div>
      {dataList.map((record: any, index: number) => (
        <div className='row-data' key={record.txnId}>
          <Row key={record.txnId}>
            <Col span={18}>
              Ref. {record.txnId}
            </Col>
            <Col span={6} style={{ textAlign: 'right' }} className='title'>
              {record.type === 'Deposit-mem' ? <Space className="dep"><WalletTwoTone /><IntlMessages id="history.depositShot" /></Space>
                : <Space className="with"><CreditCardTwoTone twoToneColor="#eb2f96" /><IntlMessages id="history.withdrawShot" /></Space>}
            </Col>
            <Col span={12}>
              {moment(record.createDate).format('DD/MM/YYYY HH:mm:ss')}
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {formatNumberCurrency(record.action, 2, 2)}
            </Col>
          </Row>
          <Divider style={{ marginTop: '3px', marginBottom: '3px' }} />
          <Row>
            <Col className='title' span={12}>
              <IntlMessages id="history.before" />
            </Col>
            <Col className='title' span={12} >
              <IntlMessages id="history.after" />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <span>{formatNumberCurrency(record.before, 2, 2)}</span>
            </Col>
            <Col span={12}>
              <span> {formatNumberCurrency(record.after, 2, 2)}</span>
            </Col>
          </Row>
        </div>
      ))}

    </div>
  )
}
