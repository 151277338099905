export function mapProviderNameToId(providerName: any) {
  if(providerName === "pgc" || providerName === "pg") {
    return 31;
  }else if(providerName === "jdb") {
    return 12;
  }else if(providerName === "pmsl") {
    return 22;
  }
  return 0;
}
