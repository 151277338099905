import { UserAccount } from "../interfaces/UserAccount"
import { useUserProfileSWR } from "./services"
import { PATHS } from "./paths"
import { api } from "./base";
import { getUserNameStorage } from "./auth";

export const USER_ACCOUNT_UPDATE_URL = "/user-account/update/:id";
export const USER_ACCOUNT_CREATE_URL = "/user-account/create";
export const USER_ACCOUNT_CHANGE_PASSWORD_URL = "/user-account/changePassword/:id";

export interface IUseUserAccount {
  account: {user : string | undefined} | undefined
}

export const useUserAccount = (): IUseUserAccount => {
  // const { data: account, error, isLoading ,mutate } = useUserProfileSWR()
  const user = getUserNameStorage() as string
  // if (error?.response?.status === 401 && !window.location.pathname.startsWith(PATHS.SIGNIN) && !window.location.pathname.startsWith(PATHS.SIGNUP)) {
  //   window.location.href = '/auth/sign-in'
  // }

  // async function createUserAccount(userAccount: Partial<UserAccount>) {
  //   const response = await api.post(USER_ACCOUNT_CREATE_URL, userAccount);
  //   mutate()
  //   return response.data;
  // }

  // async function updateUserAccount(id: string, userAccount: Partial<UserAccount>) {
  //   const response = await api.put(USER_ACCOUNT_UPDATE_URL.replace(":id", id), userAccount);
  //   mutate()
  //   return response.data;
  // }

  return {
    // createUserAccount,
    // updateUserAccount,
    account: user ? {user} : undefined,
    // isLoading: isLoading,
  }
}
export async function changePassword(id: string, data: any) {
  const response = await api.put(USER_ACCOUNT_CHANGE_PASSWORD_URL.replace(":id", id), data);
  return response.data;
}
