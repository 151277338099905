import React, { CSSProperties } from 'react';
import { CatagoryMenu } from 'components/CatagoryMenu/CatagoryMenu';
import { OwlBanner } from 'components/OwlBanner/OwlBanner';
import { CatagorySider } from 'components/CatagorySider/CatagorySider';
import { WebInformation } from 'components/WebInformation/WebInformation';
import { Lobby } from 'components/Lobby/Lobby';
import { Col, Grid, Layout, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Routes, Route } from 'react-router-dom';
import { VideoMobile } from 'components/VideoMobile/VideoMobile';
import IntlMessages from 'components/Common/IntlMessages';
export interface NavModalProps {
  gameLobby: any,
  isFirstTimeRender: boolean,
  recommendList: any
}


export const LobbyContent: React.FC<NavModalProps> = ({
  gameLobby,
  isFirstTimeRender,
  recommendList
}) => {
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  
  
  const layoutLobby: CSSProperties = {
    paddingTop: (() => {
      if (!breakpoints.lg) {
        return '0'
      } else {
        return '20px'
      }
    })(),
  }

  const contentStyle: CSSProperties = {
    // padding: (() => {
    //   if (!breakpoints.lg) {
    //     return '0 5px'
    //   } else {
    //     return '0 50px'
    //   }
    // })(),
    paddingLeft: (() => {
      if (!breakpoints.lg) {
        return '0'
      } else {
        return '30px'
      }
    })(),
    paddingRight: (() => { 
      if (!breakpoints.lg) {
        return '0'
      } else {
        return '20px'
      }
    } )(),
    paddingTop: '0px', 
    paddingBottom: '0px'
  }

  return (
    <>
      <OwlBanner />
      <div className='container-customs'>
                  <Row justify="center">
                    <Col span={24} xs={24} md={24} lg={16}>
                      <div className='header-announce'>
                      <IntlMessages id="lobby.title" />
                      </div>
                    </Col>
                  </Row>
                  
                </div>
      <CatagoryMenu />

      <Layout style={layoutLobby} className="layout-lobby">
        <CatagorySider />
        <Content style={contentStyle}>
          <Routes>
            <Route path="/lobby/:gameType"
              element={<Lobby games={gameLobby} recommendList={recommendList} isFirstTimeRender={isFirstTimeRender} />}
            />
          </Routes>
        </Content>
      </Layout>
      <WebInformation />
      <VideoMobile />
      {/* <Promotion /> */}
      {/* <AnimationSection /> */}
    </>
  )
}
