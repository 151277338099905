import { Col, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import './ScreenSelectModal.scss';
import { DesktopOutlined, MobileOutlined } from '@ant-design/icons';
export interface ScreenSelectModalProps {
  isModalOpen: boolean
  onSelectView: any
  gameSelect: any
}
export const ScreenSelectModal: React.FC<ScreenSelectModalProps> = ({ isModalOpen = false, onSelectView, gameSelect }) => {
  // const [openScreenSelect, setOpenScreenSelect] = useState(false);
  // useEffect(() => {
  //   setOpenScreenSelect(isModalOpen)
  // }, [isModalOpen]);
  return (
    <Modal
      className="screen-modal-root"
      footer={null}
      open={isModalOpen}
      centered
      closeIcon={false}
    >
      <div style={{ width: '100%', textAlign: 'center' }}>
        <h3>เปิดด้วยโหมด ?</h3>
        <span>Please select view screen mode</span>
      </div>
      <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', textAlign: 'center' }}>
        <Row style={{ width: '100%' }}>
          <Col span={12} style={{ padding: '.5rem' }}>
            <button type="button" onClick={() => {
              // setOpenScreenSelect(false)
              onSelectView(gameSelect, 1)
            }} className="regis-btn" data-toggle="modal" data-dismiss="modal" data-target="#registerModal">
              <DesktopOutlined />
              <div className="-typo-wrapper">
                <div className="-typo">Desktop</div>
              </div>

            </button>
          </Col>
          <Col span={12} style={{ padding: '.5rem' }}>
            <button type="button" onClick={() => {
              // setOpenScreenSelect(false)
              onSelectView(gameSelect, 2)
            }} className="regis-btn" data-toggle="modal" data-dismiss="modal" data-target="#registerModal">
              <MobileOutlined />
              <div className="-typo-wrapper">
                <div className="-typo">Modile</div>
              </div>

            </button>
          </Col>
        </Row>
      </div>

    </Modal>
  );
};
