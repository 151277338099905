import { memo, useEffect, useState } from 'react'
import { Collapse, CollapseProps, Grid, Segmented, Select, Space } from 'antd';
import styled from 'styled-components';
import thFlag from 'assets/nation-flag/th.png';
import enFlag from 'assets/nation-flag/en.png';
import './LangSwitch.scss';
import { SwapOutlined } from '@ant-design/icons';
import { getLocale, setLocale } from 'api/auth';
const { Option } = Select;
const NationFlag = styled.img`
  width: 23.33px;
  height: 18px;
  padding-top: 6px;
`
// const items: CollapseProps['items'] = [
//   {
//     key: 'th',
//     label: 'This is panel header 1',
//     children: <div>
//       <Space>
//         <NationFlag src={thFlag} alt="thai language" />
//         <span className="">ภาษาไทย</span>
//       </Space>
//       <Space>
//         <NationFlag src={enFlag} alt="english language" />
//         <span className="">English</span>
//       </Space>
//     </div>,
//   },

// ];
// type LangSwitchProp = {
//   ladding?: boolean;  
// };

export const LangSwitch: React.FC = () => {
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();

  const [selectedLocal, setSelectedLocal] = useState(getLocale());

  const onLangChange = (loc: string) => {
    console.log('loc', loc);
    setLocale(loc);
    setSelectedLocal(loc);
    window.location.reload();
  }

  useEffect(() => {
    setSelectedLocal(getLocale())
  }, []);


  return (
    <Collapse
      // {...props}
      ghost 
      defaultActiveKey={[selectedLocal]}
      expandIcon={({ isActive }) => <></>}
      expandIconPosition={'end'}
      className='lang-switch'
      items={[{
        key: 'langselect',
        label: selectedLocal ? selectedLocal === 'th' ?
          (
            <Space>
              <NationFlag src={thFlag} alt="thai language" />
              <span className="">ภาษาไทย</span>
              <SwapOutlined />
            </Space>
          )
          : <Space>
            <NationFlag src={enFlag} alt="english language" />
            <span className="">English</span>
            <SwapOutlined />
          </Space>
          : 'Select Language',
        children: <div>

          <Segmented block
            onChange={onLangChange}
            defaultValue={selectedLocal}
            options={[
              {
                label: (
                  <Space>
                    <NationFlag src={thFlag} alt="thai language" />
                    <span className="">ภาษาไทย</span>
                  </Space>
                ),
                value: 'th',
              },
              {
                label: (
                  <Space>
                    <NationFlag src={enFlag} alt="english language" />
                    <span className="">English</span>
                  </Space>
                ),
                value: 'en',
              }
            ]}
          />
        </div>,
      }]}
    />
  )
}

// export default memo(LangSwitch);

