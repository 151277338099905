// import React from "react";
// import logo from "./logo.svg";
import "./App.css";
// import "../public/style/index.scss"
import "./style/index.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Template } from "components/Template";
import { NotFound } from "components/Common/NotFound";
import { VerifyToken } from "components/Common/VerifyToken/VerifyToken";
import { ForceLogout } from "components/Common/ForceLogout/ForceLogout";

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/logout" element={<ForceLogout />} />
      <Route path="*" element={<Template />} />
      {/* <Route path="/lobby/:token" Component={Branch} /> */}
        <Route path="/verify/:token" element={<VerifyToken />} />
        {/* <Route path="/auth/sign-up" element={<Register />} /> */}
        {/* {account ? <Route path="*" element={<Template />} /> : <Route path="*" element={<NotFound />} />} */}
      </Routes>
    </Router>
  );
}

export default App;
