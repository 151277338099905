import React, { useMemo } from 'react';
import './VideoMobile.scss';
import titlePromotion from "assets/title-promotion.png";
import titlePromotionEng from "assets/text-promotion-eng.png";
import mobile from "assets/mobile.png";
import chLeft from "assets/ch-left.png";
import chRight from "assets/ch-right.png";
import { getLocale } from 'api/auth';
import { useInView } from 'react-intersection-observer';

export const VideoMobile: React.FC = () => {
  const lang = useMemo(() => getLocale(), []);
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the inView callback once
    rootMargin: '0px 0px 200px 0px', // Adjust to load before it is fully in view
  });

  return (

    <div className="promotion-main" ref={ref}>
      <div className='bg-promotion'></div>
      <div className="carousel-container">
        <div className='control-ch-left control-ch'>
          <img src={chLeft} className='w-100' alt="ch-left" />
        </div>
        <div className='control-ch-right control-ch'>
          <img src={chRight} className='w-100' alt="ch-right" />
        </div>
        <div className="-col-video-wrapper">
          <div className="-video-wrapper">
            <div className='title-promotion '>
              <img src={lang === "th" ? titlePromotion : titlePromotionEng} className='w-100' alt="mobile" />
            </div>
            <img alt="Big win jackpot แตกง่ายที่นี่ที่เดียว Ezslot"
              className="-video-frame img-fluid" width="960" height="1949" src={mobile} />
            {inView && (
              <video src="/assets/demo.mp4"
                poster='/assets/mask-video.png'
                muted
                autoPlay
                loop
                playsInline
                className="-video appl1-hdvd-xx">
              </video>
            )}
          </div>
        </div>
      </div>

    </div>

  )
}
export default React.memo(VideoMobile);