import { Grid, Menu } from 'antd';
import React from 'react';
import './CatagorySider.scss';
import Sider from 'antd/es/layout/Sider';
import { Link } from 'react-router-dom';
import IntlMessages from 'components/Common/IntlMessages';



export const CatagorySider: React.FC = () => {
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const siderStyle: React.CSSProperties = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: 'unset',
    display: (() => {
      if (!breakpoints.lg) {
        return 'none'
      } else {
        return 'block'
      }
    })(),
  };


  return (
    <div className="sider-root" style={siderStyle}>
      <Sider className="sider-main" width="100%" style={siderStyle}
        // onCollapse={(collapsed) => setCollapsed(collapsed)}
        breakpoint="sm"
        collapsedWidth="0"
      >
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline"  >
          <Menu.Item key="home">
            <Link to="/lobby/hot">
              <div className='button-menu button-popular'>
                <div className="menu-text-main -text-btn-image">
                    <IntlMessages id="catagoryMenu.hotGame" />
                </div>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="casino">
            <Link to="/lobby/casino" >
              <div className='button-menu button-casino'>
                <div className="menu-text-main -text-btn-image">
                <IntlMessages id="catagoryMenu.liveCasino" />
                </div>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="slot">
            <Link to="/lobby/slot">
            <div className='button-menu button-slot'>
              <div className="menu-text-main -text-btn-image">
              <IntlMessages id="catagoryMenu.slot" />
              </div>
            </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="sport">
            <Link to="/lobby/sport">
              <div className='button-menu button-sport'>
                <div className="menu-text-main -text-btn-image">
                <IntlMessages id="catagoryMenu.sport" />
                </div>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="fish">
            <Link to="/lobby/fish">
              <div className='button-menu button-fish'>
                <div className="menu-text-main -text-btn-image">
                <IntlMessages id="catagoryMenu.fish" />
                </div>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="lotto">
            <Link to="/lobby/lotto">
              <div className='button-menu button-lottery'>
                <div className="menu-text-main -text-btn-image">
                <IntlMessages id="catagoryMenu.lottery" />
                </div>
              </div>
            </Link>
          </Menu.Item>
        </Menu>

        {/* <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline"  >
          <Menu.Item key="home">
            <Link to="/lobby/hot">
              <img src={cgHit} alt="hit" style={{ width: '100%', maxHeight: 78.5 }} />
              <span className="menu-text-main -text-btn-image">
                ยอดนิยม
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="casino">
            <Link to="/lobby/casino" >
              <img src={cgCasino} alt="hit" style={{ width: '100%', maxHeight: 78.5 }} />
              <span className="menu-text-main -text-btn-image">
                คาสิโน
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="slot">
            <Link to="/lobby/slot">
              <img src={cgSlot} alt="hit" style={{ width: '100%', maxHeight: 78.5 }} />
              <span className="menu-text-main -text-btn-image">
                สล็อต
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="sport">
            <Link to="/lobby/sport">
              <img src={cgSport} alt="hit" style={{ width: '100%', maxHeight: 78.5 }} />
              <span className="menu-text-main -text-btn-image">
                Sport
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="fish">
            <Link to="/lobby/fish">
              <img src={cgFish} alt="hit" style={{ width: '100%', maxHeight: 78.5 }} />
              <span className="menu-text-main -text-btn-image">
                ยิงปลา
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="lotto">
            <Link to="/lobby/lotto">
              <img src={cgLotto} alt="hit" style={{ width: '100%', maxHeight: 78.5 }} />
              <span className="menu-text-main -text-btn-image">
                หวย
              </span>
            </Link>
          </Menu.Item>
        </Menu> */}
      </Sider>
    </div>
  );
};

