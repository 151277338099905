import { api } from "./instances";
import moment from 'moment';
export const TXN_API = "/user/transaction"

export async function searchBetList(pageIndex: number, rowPerPage: number = 50, startDateMilis: number, endDateMilis: number, gameType: number | null = null) {
  let body: any = { pageIndex, rowPerPage, startDateMilis, endDateMilis: moment(endDateMilis).endOf('day').valueOf() }
  if (gameType) {
    body = { ...body, gameType }
  }
  const response = await api.post(TXN_API, body);
  return response.data
}
