import { api } from "./instances";

export const GAME_API = "/lobby/launch"
export const GAME_LIST_API = "/lobby/game-list"

export async function openGame(lobbyId: number, isMobile: boolean, gameCode: string = "") {
  const response = await api.post(GAME_API, {lobbyId, mobile: isMobile, gameCode, ip: '127.0.0.1', homePage: 'www.google.com'});
  return response.data
}

export async function getGameList(lobbyId: number) {
  const response = await api.post(GAME_LIST_API, {lobbyId });
  return response.data
}