import Lottie from "lottie-react";
import React from "react";
import LoadingAnimation from "assets/loading.json";
type loadingProp = {
  ladding?: boolean;
};

export const LoadingFullFrame: React.FC<loadingProp> = ({ ladding }) => {
  return (
    <>
      {ladding && (
        <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center', position : 'absolute', background: 'rgb(255 255 255 / 73%)', width: '100%', zIndex: 3, margin: '-20px', borderRadius: '10px', height: '100vh' }}>
          <Lottie
            animationData={LoadingAnimation} style={{ width: '100%', maxWidth: '400px', height: 'auto' }} />
        </div>
      )}
    </>
  );
}