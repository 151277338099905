import React, { useEffect } from "react";
import { logout } from "api/auth";
import { useNavigate } from "react-router-dom";


export const ForceLogout: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => { 
    logout()
    // navigate('/lobby/hot')
  }, []);

    return (
      <>
     
      </>
    );
}