import React, { useEffect, useState } from 'react';
import './MaxBet.scss';
import { Col, Row } from 'antd';
import { getMaxBetList } from 'api/winlossService';
import { formatNumberCurrency } from 'utils/number';
import { Loading } from 'components/Common/Loading/Loading';
import IntlMessages from 'components/Common/IntlMessages';


export const MaxBet: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [maxBetList, setMaxBetList] = useState<any[]>([])
  useEffect(() => {
    getTurnOverList()
  }, [])

  const getTurnOverList = async () => {
    setLoading(true)
    const maxList = await getMaxBetList()
    setMaxBetList(maxList.result)
    setLoading(false)
  }


  return (
    <div className='content-turnover'>
      <Loading ladding={loading} />
      <Row>
        <Col span={24}>
          <h4><IntlMessages id="nav.maxBetTitle" /></h4>
          <table className="table-customs-line table  w-100" >
            <thead>
              {/* <tr>
                <th colSpan={2}  className='text-center header-table'>คาสิโนสด</th>
              </tr> */}
            </thead>
            <tbody>
              <tr>
                <th colSpan={2} className='text-center header-table'><IntlMessages id="catagoryMenu.liveCasino" /></th>
              </tr>

              {maxBetList.map((item, index) => {

                if (item.gameType === 0) {
                  return (
                    <tr key={index}>
                      <td>
                        {item.company}
                      </td>
                      <td className="text-end">
                        {formatNumberCurrency(item.max, 2, 2)} ฿
                      </td>
                    </tr>
                  )
                }
              })
              }

              <tr>
                <th colSpan={2} className='text-center header-table' ><IntlMessages id="catagoryMenu.fishTable" /></th>
              </tr>
              {maxBetList.map((item, index) => {

                if (item.gameType === 3) {
                  return (
                    <tr key={index}>
                      <td>
                        {item.company}
                      </td>
                      <td className="text-end">
                        {formatNumberCurrency(item.max, 2, 2)} ฿
                      </td>
                    </tr>
                  )
                }
              })
              }
              <tr >
                <th colSpan={2} className='text-center header-table ' ><IntlMessages id="nav.maxSportBill" /></th>
              </tr>
              {maxBetList.map((item, index) => {

                if (item.gameType === 4) {
                  return (
                    <tr key={index}>
                      <td>
                        {item.company}
                      </td>
                      <td className="text-end">
                        {formatNumberCurrency(item.max, 2, 2)} ฿
                      </td>
                    </tr>
                  )
                }
              })
              }
            </tbody></table>
        </Col>
      </Row>

    </div>
  )
}
