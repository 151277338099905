import React, { useEffect, useState } from 'react';
import './TurnOverProvider.scss';
import { Col, Row, Table, Alert, Typography, TableColumnsType } from 'antd';
import { getTurnOverByGameType } from 'api/winlossService';
import moment from 'moment';
import { formatNumberCurrency } from 'utils/number';
import IntlMessages from 'components/Common/IntlMessages';
const { Text } = Typography;
interface DataType {
  gameType: number;
  turn: number;
  wl: number;
}

const columns: TableColumnsType<DataType> = [
  {
    title: <IntlMessages id="nav.type" />,
    dataIndex: 'gameType',
    key: 'gameType',
    align: 'center',
    render: (record) => {
      return    record === 1 ? <IntlMessages id="catagoryMenu.casino" /> :
      record === 0 ? <IntlMessages id="catagoryMenu.slot" /> :
        record === 3 ? <IntlMessages id="catagoryMenu.fish" /> :
          record === 4 ? <IntlMessages id="catagoryMenu.sport" /> :
          <IntlMessages id="catagoryMenu.lottery" />
    }
  },
  {
    title: <IntlMessages id="nav.betAmount" />,
    dataIndex: 'turn',
    align: 'right',
    render: (record) => {
      return formatNumberCurrency(record, 2, 2)
    }
  },
  {
    title: <IntlMessages id="nav.winloss" />,
    align: 'right',
    dataIndex: 'wl',
    render: (wl: number, record: any) => {
      return <Text style={{textAlign: 'right'}} strong type={wl > 0 ? 'success' : 'danger'}>{wl > 0 ? '+' : '' } {formatNumberCurrency(wl, 2, 2) }</Text>
    }
  }
];



export const TurnOverProvider: React.FC = () => {
  const [turnOverList, setTurnOverList] = useState<DataType[]>([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getTurnOverList()
  }, [])

  const getTurnOverList = async () => {
    setLoading(true)
    const turnOverData = await getTurnOverByGameType(moment().subtract(30, "days").valueOf(), moment().valueOf())
    setTurnOverList(turnOverData.result)
    setLoading(false)
  }

  return (
    <div className='content-turnover'>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <h4><IntlMessages id="nav.turnOverTypeTitle" /></h4>
          <Alert message={<IntlMessages id="nav.turnOverDesc" />} type="info" showIcon />
          <Table className='table-customs' style={{ width: '100%', marginTop: 20 }} 
            dataSource={turnOverList.sort((a, b) => a.gameType - b.gameType)} columns={columns}
            pagination={false}
            loading={loading}
            rowKey={(record) => record.gameType.toString()}
            summary={pageData => {
              let totalBorrow = 0;
              let totalRepayment = 0;

              pageData.forEach(({ turn, wl }) => {
                totalBorrow += turn;
                totalRepayment += wl;
              });

              return (
                <>
                  <Table.Summary.Row key={1} style={{background: '#e3f5ff'}}>
                    <Table.Summary.Cell index={0} align='right'><IntlMessages id="nav.total" /></Table.Summary.Cell>
                    <Table.Summary.Cell index={0} align='right'>
                      <Text >{formatNumberCurrency(totalBorrow, 2, 2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0} align='right'>
                      <Text strong type={totalRepayment > 0 ? 'success' : 'danger'}>{totalRepayment > 0 ? '+' : ''} {formatNumberCurrency(totalRepayment, 2, 2)}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
            />
        </Col>
      </Row>

    </div>
  )
}
