import React, { CSSProperties, useEffect } from 'react';
import './FooterTemplate.scss';
import { motion, useAnimate, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Grid } from 'antd';
export const FooterTemplate: React.FC = () => {
  const controls = useAnimation();
  const [scope, animate] = useAnimate()
  const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '600px', });
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
  }
  const item = {
    visible: { opacity: 1, transition: { duration: 1.7 } },
    hidden: { opacity: 0 },

  }

  const logoVar = {
    visible: { opacity: 1, y: 0, transition: { duration: 1.7 } },
    hidden: { opacity: 0, y: -100 },
  }


  const imgFooter: CSSProperties = {
    maxWidth: (() => {
      if (!breakpoints.lg) {
        return '30px'
      } else {
        return '55px'
      }
    })(),
  }

  const conainterMain: CSSProperties = {
    padding: (() => {
      if (!breakpoints.lg) {
        return 'unset'
      } else {
        return '20px 8%'
      }
    })(),
  }

  const footerStyle: CSSProperties = {
    paddingBottom: (() => {
      if (!breakpoints.lg) {
        return '52px'
      } else {
        return '0px'
      }
    })(),
  }

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div className="footer-main" style={footerStyle}>


      <div className="container" style={conainterMain}>
        <motion.ul className="partner-list"
          variants={list} ref={ref}
          initial={"hidden"}
          animate={controls}
        >
          {inView && (
            <>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/104.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/1.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/2.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/108.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/26.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/98.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/60.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/102.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/7.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/15.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/14.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/62.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/5.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/103.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/208.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/54.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/114.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/200.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/209.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/42.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/66.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/204.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/67.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/12.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/46.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/32.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/53.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/25.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/44.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/210.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/107.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/55.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/72.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/40.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/93.png" alt="x" /></motion.li>
              <motion.li variants={item} ><img style={imgFooter} src="https://frontgame.s3.ap-southeast-1.amazonaws.com/mini/9.png" alt="x" /></motion.li>
            </>
          )}
        </motion.ul>
      </div>
      <div style={{ paddingBottom: '14px' }}>©2024 สงวนสิทธิ์</div>
      <div style={{ paddingBottom: '14px' }}>มีใบอนุญาตได้รับการตรวจสอบจากรัฐบาลฟิลิปปินส์ และ First Cagayan</div>
      <div style={{ paddingBottom: '24px' }}>version 1.5.30</div>
 
      {
    !inView && (
      <div style={{ height: '300px' }}>

      </div>
    )
  }
    </div >
  );
};
