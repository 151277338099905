import { logout } from "./auth";
import { api } from "./instances";

export const LOBBY = "/user/lobby"
export const PUBLIC_LOBBY = "/public/lobby"
export const PUBLIC_GAME_LIST = "/public/lobby/game-list"


export async function getLobby() {
  const account = localStorage.getItem('user')
  try{
    const response = await api.get(LOBBY);
    return response.data
  }catch(e){
    if(account){
      logout()
    }
    // window.location.href = '/lobby/hot'
    return 'error'
  }
}

export async function getPublicLobby() {
  const response = await api.get(PUBLIC_LOBBY);
  return response.data
}

export async function getPublicGameList(lobbyId: number) {
  const response = await api.post(PUBLIC_GAME_LIST, {lobbyId });
  return response.data
}
