import useIntl from "react-intl/src/components/useIntl";
import './Footerbar.scss'
import { HomeOutlined, GiftOutlined, LoginOutlined, SignatureOutlined, DribbbleOutlined, WalletOutlined} from '@ant-design/icons';
import { CSSProperties } from "react";
import { Grid } from "antd";
import { Link, useNavigate } from "react-router-dom";
import IntlMessages from "components/Common/IntlMessages";
import { useUserAccount } from "api/useUserAccount";
interface WalletProps {
  wallet: any,
  openModalPromotion: () => void
}

export const FooterBar: React.FC<WalletProps> = ({
  wallet,
  openModalPromotion
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const { account } = useUserAccount();
  const agentDomain = process.env.REACT_APP_AUTO_DOMAIN
  const rootStyle: CSSProperties = {
    display: (() => {
      if (!breakpoints.lg) {
        return 'flex'
      } else {
        return 'none'
      }
    })()
  }

  const handleHistoryClikc = (e: any) => {
    e.preventDefault()
    if (wallet !== undefined) {
      navigate('/history?page=bet')
    }
  }

  return (
    <div className="footerbar-main" style={rootStyle}>
      <div className="van-tabbar van-tabbar--fixed" id="tabBar" style={{ zIndex: 210 }}>
        <template></template>
        <div className="van-tabbar-item van-tabbar-item--active" onClick={() => navigate('/lobby/hot')}>
          <div className="van-tabbar-item__icon">
            <div className="tabbar-icon-wrap">
              <HomeOutlined />
            </div></div><div className="van-tabbar-item__text">
            <template></template>
            <span><IntlMessages id="menu.home" /></span>
          </div>
        </div>
        <div className="van-tabbar-item" onClick={openModalPromotion}>
          <div className="van-tabbar-item__icon">
            <div className="tabbar-icon-wrap">
              <GiftOutlined />
            </div>
          </div>
          <div className="van-tabbar-item__text">
            <span><IntlMessages id="menu.promotion" /></span>
          </div>
        </div>

        {!account ? (
          <>
            <div className="van-tabbar-item" onClick={() => window.location.href = `${agentDomain}`}>
              <div className="van-tabbar-item__icon">
                <div className="tabbar-icon-wrap">
                  <LoginOutlined /></div></div>
              <div className="van-tabbar-item__text">
                <span><IntlMessages id="menu.login" /></span>
              </div></div>
            <div className="van-tabbar-item" onClick={() => window.location.href = `${agentDomain}`}>
              <div className="van-tabbar-item__icon">
                <div className="tabbar-icon-wrap">
                  <SignatureOutlined />
                </div></div>
              <div className="van-tabbar-item__text">
                <span><IntlMessages id="menu.register" /></span>
              </div>
            </div>
          </>
        ): null}
 <div className="van-tabbar-item" onClick={() => window.location.href =  `${agentDomain}`}>
              <div className="van-tabbar-item__icon">
                <div className="tabbar-icon-wrap">
                <WalletOutlined />
                </div></div>
              <div className="van-tabbar-item__text">
                <span><IntlMessages id="nav.topup" /></span></div>
            </div>
        {account ? (
          <>
           
            <div className="van-tabbar-item" onClick={handleHistoryClikc}>
              <div className="van-tabbar-item__icon">
                <div className="tabbar-icon-wrap">
                  <DribbbleOutlined />
                </div></div>
              <div className="van-tabbar-item__text">
                <span><IntlMessages id="nav.betHistory" /></span></div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}