import React, { useEffect } from "react";
import { Result, Button, Spin } from 'antd';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useUserAccount } from "api/useUserAccount";
import { saveToken, setUserNameStorage } from "api/auth";

export const VerifyToken: React.FC = () => {
    const  account  = useUserAccount()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const { token } = useParams();
    // if(isLoading){
    //     return <Spin style={{ width: "100%" }} />
    // }

    useEffect(() => {
        if(token){
            setUserNameStorage('username')
            saveToken(token)
            navigate(`/lobby/hot`)
        } 
    
    }, [token])

    if(!account.account){
      // bypass the login page
        navigate(`/lobby/hot`)
        return <Spin style={{ width: "100%" }} />
    }else{
      navigate(`/lobby/hot`)
    }

    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={account ? <Link to="/"><Button type="primary">Back Home</Button></Link> : <Link to="/auth/sign-in"><Button type="primary">Sign in</Button></Link>}
        />
    );
}