import React from 'react';
import './ModalPromotion.scss';
import {  Modal, Spin } from 'antd';
import promotion1 from "../../assets/promotion/pm9.jpg";
import promotion2 from "../../assets/promotion/pm10.jpg";
import promotion3 from "../../assets/promotion/pm11.jpg";
import promotion4 from "../../assets/promotion/pm12.jpg";
import promotion5 from "../../assets/promotion/pm13.jpg";
import promotion6 from "../../assets/promotion/pm14.jpg";
import promotion7 from "../../assets/promotion/pm15.jpg";
import promotion8 from "../../assets/promotion/pm16.jpg";


interface ModalPromotionProps {
  isOpenModalPromotion: boolean
  onClose: () => void
}


export const ModalPromotion: React.FC<ModalPromotionProps> = ({ isOpenModalPromotion, onClose }) => {
  const [loading, setLoading] = React.useState(false);

  // const onFinish = (values: any) => {
  //   onSubmit(values);
  // };

  const handleCancel = () => {
    // setIsModalOpen(false);
    onClose()
  };



  return (
    <Modal
      open={isOpenModalPromotion}
      centered
      onCancel={handleCancel}
      footer={null}
      className="promotion-modal-root">
      <Spin spinning={loading}>
      <h3 className="animate-charcter"> Promotion</h3>
      <div className='item-img'>
          <img src={promotion1} alt="pm1" />
        </div>
        <div className='item-img'>
          <img src={promotion2} alt="pm2" />
        </div>
        <div className='item-img'>
          <img src={promotion3} alt="pm3" />
        </div>
        <div className='item-img'>
          <img src={promotion4} alt="pm4" />
        </div>
        <div className='item-img'>
          <img src={promotion5} alt="pm5" />
        </div>
        <div className='item-img'>
          <img src={promotion6} alt="pm6" />
        </div>
        <div className='item-img'>
          <img src={promotion7} alt="pm7" />
        </div>
        <div className='item-img'>
          <img src={promotion8} alt="pm8" />
        </div>
      </Spin>
    </Modal>



  );
};
