import React, { CSSProperties } from 'react';
import './CatagoryMenu.scss';
import { Link } from 'react-router-dom';
import { setMenuSelected } from 'api/auth';
import { Grid } from 'antd';
// import cgLotto from "assets/cg-lotto.png";
// import cgCasino from "assets/cg-casino.png";
// import cgSlot from "assets/cg-slot.png";
// import cgSport from "assets/cg-sport.png";
// import cgFish from "assets/cg-fish.png";
// import cgHit from "assets/cg-hit.png";
import IntlMessages from 'components/Common/IntlMessages';

export const CatagoryMenu: React.FC = () => {
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();

  const menuStyle: CSSProperties = {
    marginBottom: breakpoints.xs ? "0px" : "0px",
    marginTop: breakpoints.xs ? "0px" : "",
    display: (() => {
      if (!breakpoints.lg) {
        return 'block'
      } else {
        return 'none'
      }
    })(),
    padding: breakpoints.xs ? "0 1px" : "10px 12px",
  }
  // const ulStyle: CSSProperties = {
  //   padding: breakpoints.xs ? "0 0px" : "",
  //   position: breakpoints.xs ? "relative" : "relative",
  //   flexWrap: breakpoints.xs ? "wrap" : "nowrap",
  //   justifyContent: breakpoints.xs ? "center" : "center"
  // }

  // const imgStyle: CSSProperties = {
  //   maxWidth: breakpoints.xs ? "99px" : "",
  // }

  // const liStyle: CSSProperties = {
  //   margin: breakpoints.xs ? ".1rem 0" : "0 .1rem",
  //   padding: breakpoints.xs ? "0 .01rem" : "0 .1rem"
  // }

  return (
    <div style={menuStyle} className='catagory-menu' >
      {/* <div style={{ textAlign: 'center', marginTop: '0px' }}>
        <h3 style={{ textAlign: 'center', marginTop: '2%', marginBottom: '0px', color: "#00b7ff" }}>
          เว็บไซต์คาสิโนอันดับ 1 ของประเทศไทย
        </h3>
      </div> */}
      <ul className="-menu-parent navbar-nav flex-row" >
        <li className="-list-parent nav-item px-lg-2 px-1 -category-popular animated fadeInUp" data-animatable="fadeInUp" data-delay="50">
          <Link to="/lobby/hot" onClick={() => setMenuSelected("wallet")}>
          <div className='button-menu button-popular'>
            <div className="menu-text-main -text-btn-image">
            <IntlMessages id="catagoryMenu.hot" />
            </div>
          </div>
          </Link>
        </li>
        <li  className="-list-parent nav-item px-lg-2 px-1 -category-popular animated fadeInUp" data-animatable="fadeInUp" data-delay="50">
          <Link to="/lobby/casino" onClick={() => setMenuSelected("wallet")}>
            <div className='button-menu button-casino'>
              <div className="menu-text-main -text-btn-image">
              <IntlMessages id="catagoryMenu.casino" />
              </div>
            </div>
          </Link>
        </li>
        <li className="-list-parent nav-item px-lg-2 px-1 -category-popular animated fadeInUp" data-animatable="fadeInUp" data-delay="50">
          <Link to="/lobby/slot" onClick={() => setMenuSelected("wallet")}>
            <div className='button-menu button-slot'>
              <div className="menu-text-main -text-btn-image">
              <IntlMessages id="catagoryMenu.slot" />
              </div>
            </div>
          </Link>
        </li>
        <li className="-list-parent nav-item px-lg-2 px-1 -category-popular animated fadeInUp" data-animatable="fadeInUp" data-delay="50">
          <Link to="/lobby/sport" onClick={() => setMenuSelected("wallet")}>
            <div className='button-menu button-sport'>
              <div className="menu-text-main -text-btn-image">
              <IntlMessages id="catagoryMenu.sport" />
              </div>
            </div>
          </Link>
        </li>
        <li className="-list-parent nav-item px-lg-2 px-1 -category-popular animated fadeInUp" data-animatable="fadeInUp" data-delay="50">
          <Link to="/lobby/fish" onClick={() => setMenuSelected("wallet")}>
            <div className='button-menu button-fish'>
              <div className="menu-text-main -text-btn-image">
              <IntlMessages id="catagoryMenu.fish" />
              </div>
            </div>
          </Link>
        </li>
        <li className="-list-parent nav-item px-lg-2 px-1 -category-popular animated fadeInUp" data-animatable="fadeInUp" data-delay="50">
          <Link to="/lobby/lotto" onClick={() => setMenuSelected("wallet")}>
            <div className='button-menu button-lottery'>
              <div className="menu-text-main -text-btn-image">
              <IntlMessages id="catagoryMenu.lottery" />
              </div>
            </div>
          </Link>
        </li>
      </ul>
      {/* <ul style={ulStyle} className="-menu-parent navbar-nav flex-row" >
        <li style={liStyle} className="-list-parent nav-item px-lg-2 -category-popular animated fadeInUp" data-animatable="fadeInUp" data-delay="50">
          <Link to="/lobby/hot" onClick={() => setMenuSelected("wallet")}>
            <img style={imgStyle} src={cgHit} alt="cghit" className="-img -hover" />
            <span className="menu-text-main -text-btn-image">
              ยอดนิยม
            </span>
          </Link>
        </li>
        <li style={liStyle} className="-list-parent nav-item px-lg-2 -category-popular animated fadeInUp" data-animatable="fadeInUp" data-delay="50">
          <Link to="/lobby/casino" onClick={() => setMenuSelected("wallet")}>
            <img style={imgStyle} src={cgCasino} alt="cgcasino" className="-img -hover" />
            <span className="menu-text-main -text-btn-image">
              คาสิโน
            </span>
          </Link>
        </li>
        <li style={liStyle} className="-list-parent nav-item px-lg-2 -category-popular animated fadeInUp" data-animatable="fadeInUp" data-delay="50">
          <Link to="/lobby/slot" onClick={() => setMenuSelected("wallet")}>
            <img style={imgStyle} src={cgSlot} alt="cgslot" className="-img -hover" />
            <span className="menu-text-main -text-btn-image">
              สล็อต
            </span>
          </Link>
        </li>
        <li style={liStyle} className="-list-parent nav-item px-lg-2 -category-popular animated fadeInUp" data-animatable="fadeInUp" data-delay="50">
          <Link to="/lobby/sport" onClick={() => setMenuSelected("wallet")}>
            <img style={imgStyle} src={cgSport} alt="cgsport" className="-img -hover" />
            <span className="menu-text-main -text-btn-image">
              Sport
            </span>
          </Link>
        </li>
        <li style={liStyle} className="-list-parent nav-item px-lg-2 -category-popular animated fadeInUp" data-animatable="fadeInUp" data-delay="50">
          <Link to="/lobby/fish" onClick={() => setMenuSelected("wallet")}>
            <img style={imgStyle} src={cgFish} alt="cgfish" className="-img -hover" />
            <span className="menu-text-main -text-btn-image">
              Fish
            </span>
          </Link>
        </li>
        <li style={liStyle} className="-list-parent nav-item px-lg-2 -category-popular animated fadeInUp" data-animatable="fadeInUp" data-delay="50">
          <Link to="/lobby/lotto" onClick={() => setMenuSelected("wallet")}>
            <img style={imgStyle} src={cgLotto} alt="cglotto" className="-img -hover" />
            <span className="menu-text-main -text-btn-image">
              Lotto
            </span>
          </Link>
        </li>
      </ul> */}
    </div>
  );
};
