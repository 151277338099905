import React, { useEffect } from 'react';
import './ModalAdsGame.scss';
import { Grid, Modal, Spin } from 'antd';
import { getAdsGame } from 'api/adsService';
import { getAdsPopupFirstTime, setAdsPopupFirstTime } from 'api/auth';
import { mapProviderNameToId } from 'utils/mapProviderNameToId';
import { CloseOutlined } from '@ant-design/icons';

interface ModalAdsGameProps {
  account: any,
  handleClickGame: any
}


export const ModalAdsGame: React.FC<ModalAdsGameProps> = ({
  account,
  handleClickGame
}) => {
  const [loading, setLoading] = React.useState(false)
  const [gameAds, setGameAds] = React.useState<any[]>([])
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const { useBreakpoint } = Grid;

  const handleCancel = () => {
    setAdsPopupFirstTime("true")
    setIsModalOpen(false);
  }

  useEffect(() => {
    setLoading(true)
    const looked = getAdsPopupFirstTime() as string
    if (account.account && (looked === "false" || looked === null)) {
      fecthGameAds()
      setIsModalOpen(true)
    }
    setLoading(false)
  }, [])

  const fecthGameAds = async () => {
    const gameAds = await getAdsGame()
    setGameAds(gameAds.result)
  }

  const clickOpenGame = async (gameId: number, lobbyId: string) => {
    let gameObj = {
      gameList: false,
      gameType: "slot",
      isGameList: true,
      isMaintenance: false,
      lobbyId: lobbyId,
      lobbyName: "ads",
      position: 0,
      recProvider: gameId,
      recommend: null,
      title: "ads",
    }
    setAdsPopupFirstTime("true")
    handleClickGame(gameObj, 0)
    setTimeout(() => { setIsModalOpen(false) }, 200)
  }

  return (
    <Modal
      open={isModalOpen}
      centered
      onCancel={handleCancel}
      footer={null}
      closeIcon={<CloseOutlined style={{ color: 'white', fontSize: '24px', fontWeight:'bold', top: '-20px' , position: 'absolute'}} />    }
      className="ads-modal-root">
      <Spin spinning={loading}>
        {gameAds.map((game, index) => {
          let urlArr = game.linkSrc.split("?")
          let paramArr = urlArr[1].split("&")
          let providerName = paramArr[0].split("=")[1]
          let gameId = paramArr[1].split("=")[1]
          let providerId = mapProviderNameToId(providerName);
          return (
            <div key={game.id} onClick={() => clickOpenGame(providerId, gameId)}>
              <img src={game.imgSrc} alt='ads-game' style={{ maxWidth: '500px', height: 'auto', width: '100%' }} />
            </div>
          )
        })}
      </Spin>
    </Modal>

  );
};

